import React from "react";
import { motion } from "framer-motion"; // Importujemy Framer Motion
import Team from "./Team";
import training from "../static/images/training.svg";
import seo from "../static/images/seo.svg";
import technology from "../static/images/technology.svg";

// Definiujemy sekwencję animacji dla każdego elementu
const listVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.3, // Opóźnienie między kolejnymi punktami listy
    },
  },
  hidden: { opacity: 0, y: 20 },
};

// Definiujemy animację dla każdego elementu listy
const itemVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  hidden: { opacity: 0, y: 20 },
};

const AboutTheTeam = () => {
  return (
    <section className="bg-lightBeige w-full" id="next-section">
      {/* Banner Section */}
      <section className="banner bg-lightBeige text-darkGray min-h-[50vh] flex flex-col items-center justify-center text-center  w-full">
        {/* Sekcja z obrazem jako tłem */}

        <section className="flex flex-col lg:flex-row justify-center items-center">
          <div className="text-2xl md:text-2xl mx-auto max-w-[80%] lg:max-w-[40%] text-center py-10 border border-b-lightOlive">
            Tworzymy{" "}
            <span className="text-gold">unikalne strony internetowe</span>,
            które nie tylko zachwycą wyglądem, ale również zapewnią{" "}
            <span className="text-gold">doskonałe wrażenia użytkownika</span>.
            Oferujemy <span className="text-gold">perfekcję</span> w każdym
            detalu.
          </div>
          <div className="w-full flex justify-center lg:w-auto">
            
          </div>
        </section>

        <div>
          <div>
            {/* Sekcja z tytułem i rozwinięciem */}
            <div className="flex flex-col lg:flex-row lg:items-center justify-between w-full min-h-[40vh] my-12 space-y-6 lg:space-y-0 lg:space-x-10 bg-darkGray text-lightOlive  p-8">
              {/* Tytuł po lewej */}
              <h2 className="text-3xl text-gold text-center flex-1 mx-auto ">
                Własna strona internetowa <br />z perspektywą rozwoju
              </h2>

              {/* Rozwinięcie po prawej */}
              <p className="text-xl md:text-2xl text-lightOlive text-justify lg:text-left flex-1">
                Aby być zawsze o krok przed konkurencją, nieustannie rozwijamy
                nasze umiejętności, doskonalimy procesy i wprowadzamy innowacje.
                Dążymy do doskonałości na każdym etapie tworzenia stron
                internetowych, ucząc się od najlepszych i monitorując najnowsze
                trendy.
              </p>
            </div>

            {/* Nowa sekcja z blokami */}
            <section className="w-full mx-auto min-h-[50vh] my-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Blok 1 */}
              <div className=" bg-lightBeige border border-gold p-6 rounded-lg flex flex-col justify-center items-center text-center">
                <img src={training} alt="szkolenie" className="py-4" />
                <span className="text-gold font-semibold text-xl md:text-2xl mb-2 py-6">
                  Ciągłe szkolenia z UX/UI
                </span>
                <p className="text-darkGray text-lg md:text-xl">
                  Uczestniczymy w warsztatach i kursach, które pomagają nam
                  tworzyć intuicyjne i atrakcyjne dla użytkowników strony.
                </p>
              </div>

              {/* Blok 2 */}
              <div className="p-6 bg-lightBeige border border-gold  rounded-lg flex flex-col justify-center items-center text-center">
                <img src={seo} alt="SEO" className="py-6 " />
                <span className="text-gold font-semibold text-xl md:text-2xl mb-2 py-4">
                  Optymalizacja SEO
                </span>
                <p className="text-darkGray text-lg md:text-xl">
                  Regularnie aktualizujemy naszą wiedzę na temat SEO, aby strony
                  naszych klientów były widoczne w wyszukiwarkach.
                </p>
              </div>

              {/* Blok 3 */}
              <div className="p-6 bg-lightBeige rounded-lg border border-gold  flex flex-col justify-center items-center text-center">
                <img
                  src={technology}
                  alt="technologie nowe"
                  className="py-6 "
                />
                <span className="text-gold font-semibold text-xl md:text-2xl mb-2 py-4">
                  Śledzenie nowych technologii
                </span>
                <p className="text-darkGray text-lg md:text-xl">
                  Jesteśmy na bieżąco z nowymi technologiami, takimi jak AI, aby
                  wprowadzać najnowsze rozwiązania na stronach.
                </p>
              </div>
            </section>
          </div>
        </div>
      </section>

      <Team />

      {/* Czym się wyróżniamy Section */}
      <section className="py-10 w-full">
        <div className="max-w-[90%] sm:max-w-[70%] lg:max-w-[60%] mx-auto text-justify">
          <h1 className="text-3xl py-10 text-darkOlive text-center mb-6">
            Czym się wyróżniamy?
          </h1>

          {/* Motion div dla listy */}
          <motion.ul
            className="list-none space-y-4 text-darkGray text-[1rem] md:text-[1.125rem] lg:text-[1.25rem]"
            initial="hidden"
            whileInView="visible" // Animacja w trakcie przewijania
            viewport={{ once: false, amount: 0.3 }} // Uruchamia się po częściowym pojawieniu w viewport
            variants={listVariant} // Animacja dla całej listy
          >
            <motion.li variants={itemVariant}>
              ✔️ <strong>Indywidualne podejście do klienta</strong> - Każdy
              projekt dostosowujemy do specyficznych wymagań, oferując unikalne
              i spersonalizowane rozwiązania.
            </motion.li>
            <motion.li variants={itemVariant}>
              ✔️ <strong>Nowoczesne technologie i innowacyjne podejście</strong>{" "}
              - Korzystamy z najnowszych technologii, aby zapewnić szybkie,
              bezpieczne i estetyczne projekty.
            </motion.li>
            <motion.li variants={itemVariant}>
              ✔️ <strong>Doskonała obsługa klienta</strong> - Oferujemy wsparcie
              techniczne po zakończeniu projektu, zapewniając stałą opiekę i
              szybkie reakcje na potrzeby klientów.
            </motion.li>
            <motion.li variants={itemVariant}>
              ✔️ <strong>Optymalizacja i szybkość działania</strong> - Strony i
              aplikacje, które tworzymy, są zoptymalizowane pod kątem szybkości
              i przyjazności SEO.
            </motion.li>
            <motion.li variants={itemVariant}>
              ✔️ <strong>Transparentność procesu współpracy</strong> -
              Przejrzysty proces realizacji projektu ze
              stałym kontaktem z klientem.
            </motion.li>
            <motion.li variants={itemVariant}>
              ✔️ <strong>Bezpieczeństwo i ochrona danych</strong> - Stosujemy
              zaawansowane zabezpieczenia i dbamy o przestrzeganie standardów
              ochrony danych, w tym RODO.
            </motion.li>
            <motion.li variants={itemVariant}>
              ✔️ <strong>Skalowalność i elastyczność rozwiązań</strong> - Nasze
              projekty są skalowalne, co pozwala na ich rozwój wraz z potrzebami
              biznesowymi klienta.
            </motion.li>
            <motion.li variants={itemVariant}>
              ✔️ <strong>Krótki czas realizacji</strong> - Szybka i terminowa realizacja
              projektów, przy jednoczesnym utrzymaniu wysokiej jakości.
            </motion.li>
          </motion.ul>
        </div>
      </section>
    </section>
  );
};

export default AboutTheTeam;
