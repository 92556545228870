import React from 'react'
import Banner from '../components/Banner'
import AboutTheTeam from '../components/AboutTheTeam'
import Header from "../components/Header"
import Instagram from '../components/Instagram'

const Onas = () => {
  return (
    <div>
       <Header title="Poznaj nas i dowiedz się jak działamy" text="QUBIT SOFTWARE"/>
      <AboutTheTeam />
    <Banner />
    <Instagram />
    </div>
  )
}

export default Onas
