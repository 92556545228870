import React from "react";
import ContactForm from "../components/ContactForm";
import Map from "../components/Map";
import Header from "../components/Header";

const Kontakt = () => {
  return (
    <div>
      <Header title="Skontaktuj się z nami" text="Skontaktuj się z nami"/>
      <ContactForm />
      <div className="px-10">
        {" "}
        <Map />
      </div>
    </div>
  );
};

export default Kontakt;
