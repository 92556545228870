import React from "react";
import { useNavigate } from "react-router-dom";
import Instagram from "../components/Instagram";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="bg-lightBeige min-h-screen flex flex-col justify-center items-center text-center px-4">
      <h1 className="text-darkGray text-6xl md:text-8xl font-bold mb-6">404</h1>
      <h2 className="text-darkOlive text-2xl md:text-3xl font-semibold mb-4">
        Oops! Nie znaleziono strony
      </h2>
      <p className="text-darkGray text-lg md:text-xl mb-8">
        Strona, której szukasz, mogła zostać przeniesiona lub nie istnieje.
      </p>
      <button
        onClick={handleGoBack}
        className="bg-gold text-darkGray px-6 py-3 rounded-3xl text-lg font-medium hover:bg-darkGold transition-colors duration-300"
      >
        Powrót na stronę główną
      </button>
      <Instagram />
    </div>
  );
};

export default NotFound;
