import React from 'react'
import ComingSoon from '../components/ComingSoon'
import Instagram from '../components/Instagram'

const GotoweSzablony = () => {
  return (
    <div>
      <ComingSoon text="Gotowe szablony - już niedługo w naszej ofercie!" description="Już niedługo będziesz mógł skorzystać z gotowych szablonów. Prosimy o cierpliwość. W między czasie możesz skontaktować się z nami poprzez nasz formularz kontaktowy." />
    <Instagram />
    </div>
  )
}

export default GotoweSzablony
