import React from 'react'
import ComingSoon from '../components/ComingSoon'
import Instagram from '../components/Instagram'

const SklepyInternetowe = () => {
  return (
    <div>
      <ComingSoon text="Oferta w trakcie przebudowy" description="Dbamy o najmniejsze detale, dlatego prosimy o cierpliwość, a już niedługo będziesz mógł zobaczyć ofertę na naszej stronie. W między czasie możesz skontaktować się z nami poprzez nasz formularz kontaktowy."/>
    <Instagram />
    </div>
  )
}

export default SklepyInternetowe
