import React from "react";
import { blocksData } from "../constants/index";
import ContactForm from "../components/ContactForm";
import OpinieSlider from "../components/OpinieSlider";
import Banner from "../components/Banner";
import { Link as ScrollLink } from "react-scroll";
import Kroki from "../components/Kroki";
import Accordion from "../components/Accordion"
import Pakiety from "../components/Pakiety";
import Uslugi from "../components/Uslugi";
import image from "../static/images/GlassLogo.svg"
import Instagram from "../components/Instagram";

const StronaGłówna = () => {
  return (
    <>
      <div
        className="relative pt-[1rem] flex flex-col lg:flex-row justify-center items-center text-center text-[4rem] font-extrabold min-h-[100vh] border border-b-lightOlive"
       
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-darkGray"></div>

        <div className="absolute right-[2rem] lg:right-[6rem] top-[50%] transform -translate-y-[50%] flex flex-col items-center z-50">
          {/* Vertical Line */}
          <div className="h-[30vh] md:h-[50vh] border-l-2 border-gold flex flex-col justify-center items-center relative">
            {/* Instagram text */}
            <p className="text-gold m-2 md:m-4 text-base absolute top-1/2 left-0 transform -translate-x-[50%] -translate-y-[50%] rotate-90 origin-center">
            <a
              href="https://www.instagram.com/qubit.soft/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            </p>
          </div>
        </div>

        <div className="relative z-10 p-8 rounded-lg">
          {/* Introductory Text */}
          <p className="text-gold font-Gugi text-[3rem]">
            qubit
          </p>
          <p className="text-lightOlive text-[2.5rem] md:text-[3rem] fade-in-out">
            SOFTWARE
          </p>
          <p
            className="text-lightBeige text-[1rem] fade-in md:mb-9 font-light max-w-[600px] my-16 mx-auto 
      px-6 text-center"
          >
            Specjalizujemy się w projektowaniu, budowaniu i skalowaniu cyfrowych
            produktów.{" "} Zbudujemy dla Ciebie wizytówkę dla Twojej firmy według Twoich zapotrzebowań.
          </p>

          {/* Call to Action Buttons */}
          <div className="flex justify-center gap-6">
            {/* Smooth Scroll Button */}
            <ScrollLink
              to="offer-section"
              smooth={true}
              duration={500}
              className="text-darkGray bg-gold hover:text-white px-6 py-3 rounded-3xl text-lg font-extralight transition-all duration-300 ease-in-out cursor-pointer"
            >
              Poznaj naszą ofertę
            </ScrollLink>
          </div>
        </div>
        <div className="relative z-10 lg:w-1/3 flex justify-center items-center">
    <img
      src={image}
      alt="logo qubit"
      className="w-full max-w-[300px]  hidden lg:block"
    />
  </div>
      </div>

      <Uslugi blocks={blocksData} />
      <Kroki />
      <Pakiety />
      <OpinieSlider />
      <Accordion />
      <Instagram />
      <Banner />
      <ContactForm />
    </>
  );
};

export default StronaGłówna;
