import React from 'react'

const ComingSoon = ({text, description}) => {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen m-auto md:w-[80vw] w-full">
        <div className="text-center p-5">
          <h1 className="text-4xl font-bold text-lightOlive mb-4">{text}</h1>
          <p className="text-lg text-lightOlive mb-6">{description}</p>
          <div className="flex flex-col space-y-4 w-full max-w-sm m-auto">
          <button className=" bg-gold  hover:text-white rounded-lg transition ease-in-out duration-300 p-4 text-lg">
            <a href="/" className="block text-center w-full">
              Powrót na Stronę Główną
            </a>
          </button>
          <button className=" bg-gold hover:text-white rounded-lg transition ease-in-out duration-300 p-4 text-lg">
            <a href="/Kontakt" className="block text-center w-full">
              Formularz kontaktowy
            </a>
          </button>
        </div>
        </div>
      </div>
    );
  };
  
  export default ComingSoon;