import React from 'react';

const InfoBlock = ({ title, description, imageSrc, imageAlt }) => {
  return (
    <div
      id="next-section"
      className="flex flex-col-reverse md:flex-row items-center m-auto gap-10 p-5 my-5 w-full max-w-6xl"
    >
      {/* Tekst */}
      <div className="w-full md:w-1/2 p-2 text-center md:text-left">
        <h2 className="text-2xl mb-2 text-lightOlive uppercase">{title}</h2>
        <p className="text-base text-lightOlive">{description}</p>
      </div>

      {/* Obraz */}
      <div className="w-full md:w-1/2 p-2">
        <img
          className="w-full h-auto object-cover rounded-lg shadow-lg opacity-10"
          src={imageSrc}
          alt={imageAlt || 'Image'}
        />
      </div>
    </div>
  );
};

export default InfoBlock;
