import React from "react";
import { blocks } from "../constants/index";

const Cennik = () => {
  return (
    <>
      {/* Sekcja "Co oferujemy?" */}
      <section className="relative overflow-hidden bg-darkGray">
        {/* Napis h1 przecinający górny border */}
        <div className="items-center justify-center m-auto px-4 py-16 md:w-[70vw]">
          <p className="text-lg md:text-xl text-center text-gold leading-relaxed">
            Wybór odpowiedniego rodzaju strony internetowej to <strong>kluczowy krok w
            realizacji Twoich celów biznesowych. </strong>Niezależnie od tego, czy
            potrzebujesz prostej strony skupionej na jednym celu, rozbudowanej
            witryny z wieloma podstronami, czy też w pełni funkcjonalnego sklepu
            internetowego – każda opcja ma swoje unikalne zalety i możliwości.
            <br />
            <br />
            <strong>Aby ułatwić Ci podjęcie decyzji,</strong> przygotowaliśmy zestawienie różnych
            typów stron, <strong>ich najważniejszych zalet oraz szczegółowy zakres
            oferowanych usług. </strong>Dzięki temu łatwiej dopasujesz rozwiązanie do
            swoich potrzeb i oczekiwań. <strong>Zapoznaj się z poniższymi propozycjami,
            aby wybrać stronę, która najlepiej odpowiada Twoim wymaganiom!</strong>
          </p>
        </div>

        <div id="next-section" className="relative mb-12 md:mb-24 mt-10">
          <div className="flex flex-col flex-wrap justify-center items-center space-y-6 md:space-y-8 lg:space-y-12">
            {blocks.map((block, index) => (
              <div
                key={index}
                className="relative w-full sm:max-w-[70vw] p-4 flex flex-col"
              >
                <div className="relative flex flex-col flex-1 border border-gold rounded-lg shadow-lg overflow-visible">
                  {/* Numeracja */}
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 text-3xl md:text-5xl font-bold text-gold bg-darkGray">
                    {index + 1}
                  </div>
                  {/* Blok tekstowy */}
                  <div className="flex-1 p-4 pt-16 hover:bg-darkGray text-lightOlive transition-colors duration-500">
                    <h2 className="text-m md:text-xl font-bold mb-2">
                      {block.title}
                    </h2>
                    <p className="text-base md:text-lg mb-4">
                      {block.description}
                    </p>
                    {block.benefits && (
                      <div className="mb-4">
                        <h3 className="text-m md:text-xl font-semibold mb-2">
                          Zalety:
                        </h3>
                        <ul className="list-disc list-inside text-base md:text-lg">
                          {block.benefits.map((benefit, i) => (
                            <li key={i} className="mb-2">
                              {benefit}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {block.scope && (
                      <div>
                        <h3 className="text-lg md:text-xl font-semibold mb-2">
                          Zakres usługi:
                        </h3>
                        <ul className="list-disc list-inside text-base md:text-lg">
                          {block.scope.map((item, i) => (
                            <li key={i} className="mb-2">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Cennik;
