import React from "react";
import { Link } from "react-router-dom";

const Pakiety = () => {
  return (
    <div className="bg-lightBeige mx-auto w-full">
      <h1 className="md:text-4xl text-2xl text-center pt-12 mb-12">Wybierz swój pakiet</h1>
      <section className="flex flex-col md:flex-row gap-8 pb-6 mx-4 md:mx-0">
        {" "}
        {/* Dodane mx-4 na mobilkach */}
        <div className="flex-1 bg-lightBeige border border-gold pb-6 text-center transform transition duration-300 hover:scale-105">
          <h2 className="md:text-xl text-lg font-semibold text-gold mb-4 bg-darkGray p-6">
            PAKIET PODSTAWOWY
          </h2>
          <ul className="text-gray-600 mb-6 text-lg">
            <li className="text-4xl py-4">od 1500PLN*</li>
            <li>czas realizacji od 2 tygodni</li>
            <li>Strona Główna</li>
            <li>O nas</li>
            <li>Oferta</li>
            <li>Galeria od 8 zdjęć</li>
            <li>FAQ</li>
            <li>Formularz kontaktowy (Imię, Email, Wiadomosc)</li>
            <li>Kontakt</li>
            <li>Poczta</li>
            <li>2 poprawki</li>
         
          </ul>
          <Link
          to="/oferta"
          className="text-darkGray text-center bg-gold hover:text-white border px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out"
        >
          Więcej szegółów w ofercie
        </Link>
        </div>
        <div className="flex-1 bg-lightBeige border border-gold pb-6 text-center transform transition duration-300 hover:scale-105">
          <h2 className="md:text-xl text-lg font-semibold text-gold mb-4 bg-darkGray p-6">
            PAKIET ZAAWANSOWANY
          </h2>
          <ul className="text-gray-600 mb-6 text-lg">
            <li className="text-4xl py-4">od 2300PLN*</li>
            <li>Zawiera wszystko z pakietu podstawowego</li>
            <li>Czas realizacji od 4 tygodni</li>
            <li>Blog</li>
            <li>Galeria od 10 zdjęć</li>
            <li>Formularz kontaktowy (Imię, Email, Wiadomosc)</li>
            <li>4 poprawki</li>
          
          </ul>
          <Link
          to="/oferta"
          className="text-darkGray text-center bg-gold hover:text-white border px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out"
        >
          Więcej szegółów w ofercie
        </Link>
        </div>
        <div className="flex-1 bg-lightBeige border border-gold pb-6 text-center transform transition duration-300 hover:scale-105">
          <h2 className="md:text-xl text-lg font-semibold text-gold mb-4 bg-darkGray p-6">
            PAKIET PREMIUM
          </h2>
          <ul className="text-gray-600 mb-6 text-lg">
            <li className="text-4xl py-4">od 5000PLN*</li>
            <li>Czas realizacji od 6 tygodni</li>
            <li>Zawiera wszystko z pakietu zaawansowanego</li>
            <li>Galeria od 14 zdjęć</li>
            <li>Zaawansowany formularz</li>
            <li>Opinie</li>
            <li>Dodatkowy Język</li>
            <li>6 poprawek</li>
      
          </ul>
          <Link
          to="/oferta"
          className="text-darkGray text-center bg-gold hover:text-white border px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out"
        >
          Więcej szegółów w ofercie
        </Link>
         
        </div>
      </section>
    </div>
  );
};

export default Pakiety;
