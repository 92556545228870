import React from "react";
import Tabela from "../components/Tabela";
import Header from "../components/Header";
import InfoBlock from "../components/InfoBlock";
import Accordion from "../components/Accordion";
import photo from "../static/images/stepsHero.webp"
import Instagram from "../components/Instagram";

const StronyWWW = () => {
  return (
    <div className="bg-darkGray">
      <Header title="Strony Internetowe" text="Strony Internetowe" />
      <InfoBlock
  title="Poznaj możliwości naszych Stron Internetowych"
  description={
    <>
      Jeśli już <strong>podjąłeś decyzję</strong> o stworzeniu lub modernizacji swojej <strong>strony internetowej</strong>, jesteśmy tutaj, aby Ci pomóc! <strong>Nasz zespół</strong> tworzy <strong>nowoczesne</strong>, <strong>funkcjonalne</strong> i <strong>estetyczne strony WWW</strong>, które są nie tylko <strong>wizytówką Twojej firmy</strong>, ale także <strong>narzędziem do osiągania Twoich celów biznesowych</strong>.
      <br /><br />
      Dzięki <strong>naszemu doświadczeniu</strong> i <strong>indywidualnemu podejściu</strong> gwarantujemy, że każda strona <strong>spełni Twoje oczekiwania</strong> – od <strong>projektu graficznego</strong> po <strong>wdrożenie</strong> i <strong>wsparcie techniczne</strong>.
      <br /><br />
      Dołącz do grona <strong>naszych zadowolonych klientów</strong> i pozwól nam zadbać o <strong>Twoją obecność w sieci</strong>! A poniżej znajdziesz <strong>nasze pakiety</strong>. Oczywiście możemy też <strong>zindywidualizować ofertę</strong> pod Twoje potrzeby, także nie zwlekaj z kontaktem!
    </>
  }
  imageSrc={photo}
/>
      <Tabela />
      <Accordion />
      <Instagram />
    </div>
  );
};

export default StronyWWW;
