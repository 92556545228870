import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"; // Import ikon
import { navLinks } from "../constants";
import close from "../static/images/close.svg";
import menu from "../static/images/menu.svg";
import logo from "../static/images/GlassLogo.svg";

const Nav = ({ onNavigation }) => {
  const [toggle, setToggle] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [activeSubmenu, setActiveSubmenu] = React.useState(null);
  let closeTimeout = null;

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const handleLinkClick = (path) => {
    onNavigation(path); // Trigger the custom navigation function
    if (toggle) {
      handleToggle(); // Close the menu if it's open
    }
  };

  const handleMouseEnter = (id) => {
    if (closeTimeout) clearTimeout(closeTimeout); // Clear any pending close actions
    setActiveMenu(id); // Aktywuj submenu
  };

  const handleMouseLeave = () => {
    // Add a slight delay before closing
    closeTimeout = setTimeout(() => {
      setActiveMenu(null); // Dezaktywuj submenu
    }, 200);
  };

  const handleSubmenuToggle = (id) => {
    setActiveSubmenu((prev) => (prev === id ? null : id));
  };

  return (
    <div className="absolute top-0 left-0 w-full z-50 mt-20">
      {/* Desktop Menu */}
      <div className="hidden md:flex align-middle flex-row justify-between items-center h-auto p-4 w-full max-w-[1200px] mx-auto border-b border-lightOlive">
        <a href="/">
          <img src={logo} alt="logo" className="w-[60px] h-[60px] ml-6" />
        </a>
        <div className="flex items-center">
          <ul className="list-none flex flex-1 justify-center items-center">
            {navLinks.map((nav) => (
              <li
                key={nav.id}
                className="mx-4 relative"
                onMouseEnter={() => handleMouseEnter(nav.id)}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to={nav.path}
                  onClick={() => handleLinkClick(nav.path)}
                  className="text-lg transition-colors duration-300 ease-in-out text-lightOlive font-bold"
                >
                  {nav.title}
                  <span className="absolute left-0 bottom-[-2px] w-full h-[2px] bg-gold transform scale-x-0 origin-left transition-transform duration-300 ease-in-out"></span>
                </Link>
                {nav.submenu && activeMenu === nav.id && (
                  <ul className="absolute top-full left-0 bg-blue-gray-800 shadow-lg rounded-md mt-[-4px] py-2 w-48">
                    {nav.submenu.map((submenu) => (
                      <li key={submenu.id} className="px-4 py-2 border-b border-darkOlive last:border-b-0 transition-colors duration-500">
                        <Link
                          to={submenu.path}
                          onClick={() => handleLinkClick(submenu.path)}
                          className="text-lightOlive font-bold text-lg"
                        >
                          {submenu.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center justify-between w-full px-4 py-4 absolute">
        <a href="/">
          <img src={logo} alt="logo" className="h-[60px] m-6" />
        </a>
        {!toggle && (
          <img
            src={menu}
            alt="sidebar menu hamburger"
            className="w-[28px] h-[28px] object-contain cursor-pointer z-50"
            onClick={handleToggle}
          />
        )}
      </div>

      {/* Mobile Menu Dropdown */}
      <div
        className={`md:hidden fixed top-0 left-0 h-full w-[60vw] min-h-[100vh] bg-darkGray shadow-lg z-10 transition-transform duration-700 ease-in-out ${
          toggle ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex justify-end p-4">
          <img
            src={close}
            alt="close menu"
            className="w-[28px] h-[28px] object-contain cursor-pointer"
            onClick={handleToggle}
          />
        </div>
        <div className="flex flex-col items-center mt-4">
          <a href="/">
            <img src={logo} alt="logo qubit" className="h-[60px] m-6" />
          </a>
        </div>
        <ul className="list-none flex flex-col items-center py-4">
          {navLinks.map((nav) => (
            <li key={nav.id} className="my-4 relative">
              <div className="flex items-center justify-between w-full px-4">
                <Link
                  to={nav.path}
                  onClick={() => handleLinkClick(nav.path)}
                  className="text-lg transition-colors duration-300 ease-in-out relative text-white"
                >
                  {nav.title}
                </Link>
                {nav.submenu && (
                  <button
                    onClick={() => handleSubmenuToggle(nav.id)}
                    className="text-white text-lg ml-2 focus:outline-none"
                  >
                    {activeSubmenu === nav.id ? "-" : "+"}
                  </button>
                )}
              </div>
              {nav.submenu && activeSubmenu === nav.id && (
                <ul className="list-none mt-2 ml-4 pl-4 border-l-2 border-gray-600">
                  {nav.submenu.map((submenu) => (
                    <li key={submenu.id} className="my-2">
                      <Link
                        to={submenu.path}
                        onClick={() => handleLinkClick(submenu.path)}
                        className="text-sm text-gray-300 hover:text-white"
                      >
                        {submenu.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}

          {/* Phone number with icon */}
          <li className="my-4 relative flex items-center text-white text-lg">
            <FontAwesomeIcon icon={faMobileAlt} className="mr-2 text-gold" />
            <a href="tel:+48690363690">690 363 690</a>
          </li>

          {/* Email with icon */}
          <li className="my-4 relative flex items-center text-white text-lg">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-gold" />
            <a href="mailto:info@qubity.pl">info@qubity.pl</a>
          </li>
        </ul>
      </div>

      {/* Overlay (Visible only when toggle is true) */}
      {toggle && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[5]"
          onClick={handleToggle}
        ></div>
      )}
    </div>
  );
};

export default Nav;
