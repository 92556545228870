import React from 'react'
import Cennik from "../components/Cennik"
import Header from "../components/Header"
import { Link } from "react-router-dom";
import Accordion from "../components/Accordion"
import Uslugi from '../components/Uslugi';
import Instagram from '../components/Instagram';

const Oferta = () => {
  return (
    <div>
      <Header 
                title="Poznaj naszą ofertę"
                text="Poznaj naszą ofertę"
              
              />
     <Cennik />
     <Uslugi />
     <Accordion />
           <div className="flex justify-center w-full bg-gold p-10">
             <Link
               to="/BezplatneKonsultacje"
               className="text-darkGray hover:bg-gold hover:text-white border border-darkGray px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out"
             >
               Zgłoś się na darmowe konsultacje!
             </Link>
           </div>
           <Instagram />
    </div>
  )
}

export default Oferta
