import React from "react";
import { instagramImages } from "../constants";
import instagramIcon from "../static/images/instagram.svg";

const Instagram = () => {
  return (
    <div className="bg-lightBeige p-4 py-20">
      <div className="flex items-center my-10">
        <div className="flex-grow border-t-2 border-gold"></div>
        <p className="text-center mx-4 text-3xl md:text-[3rem]">Instagram</p>
        <div className="flex-grow border-t-2 border-gold"></div>
      </div>

      <p className="text-center mb-10 md:text-[2rem] text-gold font-bold">
        Bądź z nami na bieżąco
      </p>
      <div className="flex justify-center items-center gap-4 mb-10">
        <a
          href="https://www.instagram.com/qubit.soft/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2"
        >
          {/* Ikona w kółku */}
          <div className="bg-lightOlive hover:bg-gold transition ease-in-out duration-700 rounded-full flex justify-center items-center w-20 h-20">
            <img src={instagramIcon} alt="instagram" className="w-10 h-10" />
          </div>
          {/* Nazwa konta */}
          <div className="text-lg font-bold hover:underline text-gray-700">
            qubit.soft
          </div>
        </a>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 ">
        {instagramImages.map((imageObj) => (
          <a
            href="https://www.instagram.com/qubit.soft/"
            target="_blank"
            rel="noopener noreferrer" // Zwiększa bezpieczeństwo otwierania linków w nowych oknach
            key={imageObj.id}
            className="w-full aspect-square overflow-hidden"
          >
            <img
              src={imageObj.image}
              alt={imageObj.alt}
              className="w-full h-full object-cover"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Instagram;
